import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" iewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M6102 12308 c-805 -26 -1589 -219 -2337 -575 -159 -76 -430 -225
-598 -329 -529 -327 -1099 -845 -1505 -1367 -892 -1147 -1332 -2595 -1227
-4042 76 -1050 424 -2043 1023 -2920 205 -299 403 -536 682 -815 118 -119 231
-229 250 -245 19 -17 67 -57 105 -91 154 -134 511 -398 665 -491 753 -458
1413 -707 2235 -842 307 -51 575 -71 930 -71 999 1 1948 244 2833 728 787 430
1486 1059 1999 1798 256 368 422 674 621 1139 36 84 129 343 171 475 197 624
286 1306 260 1998 -46 1213 -473 2389 -1217 3355 -288 375 -683 771 -1057
1062 -153 119 -364 267 -490 344 -452 275 -858 462 -1340 617 -644 207 -1294
296 -2003 272z m665 -93 c598 -52 1114 -169 1648 -375 1247 -482 2296 -1383
2950 -2536 431 -759 675 -1549 750 -2427 17 -201 20 -680 5 -877 -74 -971
-376 -1888 -889 -2695 -139 -220 -318 -466 -446 -615 -22 -25 -65 -75 -95
-111 -81 -95 -363 -383 -451 -461 -240 -210 -373 -317 -554 -445 -637 -450
-1357 -770 -2095 -932 -466 -102 -781 -136 -1270 -136 -348 0 -463 7 -760 46
-934 121 -1793 457 -2592 1010 -422 293 -922 766 -1214 1149 -27 36 -51 67
-54 70 -51 48 -307 431 -418 626 -210 368 -384 761 -501 1133 -75 237 -91 299
-157 586 -7 33 -24 125 -38 205 -97 572 -110 1160 -36 1730 18 138 27 194 60
375 88 476 283 1042 514 1495 165 324 401 702 583 934 409 521 926 999 1437
1327 829 532 1731 840 2711 924 217 18 696 18 912 0z"
          />
          <path
            d="M2590 10029 c-184 -16 -295 -80 -326 -186 -9 -31 -14 -71 -11 -90 8
-48 50 -99 105 -128 l47 -24 -66 -1 c-78 0 -94 -12 -84 -62 26 -119 114 -190
266 -217 89 -15 287 -13 379 3 77 14 163 46 174 65 14 22 25 10 20 -18 -5 -22
-2 -32 12 -39 22 -11 209 -15 272 -6 39 6 45 11 68 55 l24 49 130 0 130 0 24
-49 c22 -42 30 -49 63 -54 67 -12 965 -8 1006 4 28 8 42 7 52 -1 17 -14 268
-11 282 3 4 5 10 61 13 125 l5 116 89 -122 c84 -116 90 -122 125 -123 69 -2
96 -2 111 0 9 0 56 55 105 122 l90 120 5 -115 c3 -63 9 -118 13 -123 10 -10
507 -17 555 -8 29 5 37 13 54 56 l21 49 134 0 134 0 20 -46 c12 -25 30 -49 42
-53 34 -13 274 -14 297 -1 11 6 20 18 20 26 0 9 -56 120 -124 247 -68 128
-141 264 -161 302 -69 128 -43 115 -224 115 -138 0 -160 -2 -173 -17 -9 -10
-75 -129 -148 -265 -73 -137 -135 -248 -139 -248 -3 0 -5 110 -3 245 2 160 -1
252 -8 265 -10 19 -21 20 -180 20 l-169 0 -108 -140 c-59 -76 -113 -139 -118
-140 -6 0 -15 8 -21 18 -6 9 -55 72 -108 140 l-97 122 -160 0 c-92 0 -169 -4
-180 -10 -18 -10 -19 -23 -19 -218 l0 -207 -237 6 c-131 3 -239 6 -240 7 -2 1
-3 91 -3 200 0 141 -3 201 -12 210 -17 17 -268 17 -291 0 -15 -12 -17 -42 -19
-261 l-3 -248 -124 233 c-68 128 -133 245 -143 261 l-19 27 -159 0 -159 0 -20
-29 c-12 -16 -21 -32 -21 -35 0 -3 -26 -54 -58 -113 -33 -59 -83 -153 -112
-208 -29 -55 -55 -103 -59 -107 -3 -4 -6 14 -7 40 -1 63 -25 102 -88 141 l-51
31 57 0 c69 0 90 16 81 63 -16 84 -69 151 -153 189 -72 34 -262 50 -420 37z
m348 -49 c96 -22 167 -86 187 -172 l7 -28 -129 0 c-117 0 -132 2 -148 20 -35
38 -157 52 -251 29 -47 -12 -75 -42 -56 -61 6 -6 81 -16 169 -24 204 -17 237
-22 298 -45 66 -25 105 -65 112 -117 12 -92 -46 -166 -161 -204 -64 -21 -89
-23 -261 -23 -158 0 -199 3 -247 19 -70 23 -125 67 -149 120 -35 76 -35 76
105 76 103 0 126 -3 137 -17 24 -29 76 -43 156 -43 92 0 149 14 163 39 17 33
-12 42 -192 56 -199 15 -257 27 -314 60 -54 32 -74 64 -74 120 0 105 76 169
235 201 82 16 331 13 413 -6z m834 -47 c14 -27 87 -162 162 -302 75 -140 136
-259 136 -263 0 -5 -60 -7 -132 -6 l-133 3 -25 53 -25 52 -155 0 -155 0 -25
-53 -25 -52 -132 -3 c-73 -1 -133 1 -133 6 0 5 24 54 54 108 71 131 225 419
249 467 l19 37 148 0 147 0 25 -47z m558 -163 l0 -210 245 0 245 0 0 -84 c0
-46 -3 -91 -6 -100 -5 -14 -47 -16 -365 -16 l-359 0 0 310 0 310 120 0 120 0
0 -210z m960 88 c52 -67 107 -135 121 -152 l26 -29 117 151 118 152 154 0 154
0 0 -310 0 -310 -120 0 -120 0 -1 143 c-1 78 -1 150 0 160 4 34 -15 15 -94
-95 -158 -218 -147 -208 -213 -208 l-58 0 -115 159 c-63 88 -117 160 -121 160
-5 1 -8 -71 -8 -159 l0 -160 -120 0 -120 0 0 310 0 310 153 0 152 0 95 -122z
m1410 -21 c75 -142 129 -244 208 -390 23 -43 42 -80 42 -82 0 -3 -60 -5 -134
-5 l-135 0 -25 55 -25 55 -156 0 -155 0 -25 -55 -24 -55 -129 0 c-71 0 -131 3
-134 6 -3 3 13 40 36 82 23 42 70 129 103 192 34 63 86 162 117 219 31 58 56
108 56 113 0 4 69 8 153 8 l152 0 75 -143z m-3879 -54 c10 -10 -10 -11 -92 -6
-139 8 -157 26 -22 21 63 -2 107 -8 114 -15z m-61 -222 c50 -7 52 -8 27 -15
-36 -11 -158 -2 -183 12 -15 9 -8 11 41 11 33 -1 85 -4 115 -8z"
          />
          <path
            d="M3560 9718 c-18 -40 -35 -79 -37 -85 -4 -10 15 -13 77 -13 62 0 81 3
77 13 -23 59 -71 157 -77 157 -4 0 -22 -33 -40 -72z m59 -50 c1 -5 -8 -8 -19
-8 -23 0 -24 4 -8 33 11 21 11 21 19 2 4 -11 8 -23 8 -27z"
          />
          <path
            d="M6437 9723 c-20 -43 -37 -84 -37 -91 0 -8 24 -12 80 -12 44 0 80 3
79 8 0 4 -19 45 -43 90 l-42 84 -37 -79z m63 -55 c0 -4 -9 -8 -20 -8 -21 0
-24 8 -13 36 6 16 7 16 20 -2 7 -10 13 -21 13 -26z"
          />
          <path
            d="M7391 9989 c-7 -21 -11 -136 -11 -330 0 -289 1 -300 21 -320 20 -20
27 -20 332 -16 292 3 315 5 360 25 26 12 61 30 76 41 l28 20 -1 -36 c-1 -46
12 -51 155 -52 155 -1 167 2 193 60 l21 49 132 0 133 0 24 -49 c19 -36 32 -50
52 -54 41 -8 277 -3 292 6 26 17 26 14 -142 330 l-47 87 120 0 121 0 0 -193
c0 -255 -10 -240 167 -235 105 2 136 6 143 18 5 8 10 104 10 213 l0 197 125 0
c69 0 125 -2 125 -5 0 -3 -33 -67 -74 -142 -121 -228 -127 -239 -121 -256 4
-8 15 -17 25 -20 36 -9 257 -8 283 2 17 7 35 27 49 56 l22 45 132 0 132 0 23
-47 c23 -46 27 -48 74 -55 66 -8 204 -10 243 -2 57 12 57 15 -53 219 -32 61
-98 184 -147 275 -48 91 -92 172 -97 180 -7 12 -37 15 -176 15 l-169 0 -45
-87 c-26 -48 -50 -90 -54 -94 -4 -4 -7 31 -7 77 0 53 -5 90 -12 97 -18 18
-851 17 -866 -1 -6 -7 -12 -49 -14 -92 l-3 -80 -50 90 -50 90 -162 3 -161 2
-19 -27 c-11 -16 -61 -107 -112 -203 -52 -96 -98 -179 -103 -185 -7 -7 -9 3
-4 31 8 54 -10 147 -39 204 -30 59 -95 123 -153 152 -46 22 -55 23 -378 26
l-332 3 -11 -32z m704 -33 c110 -51 185 -190 171 -314 -12 -109 -74 -200 -171
-250 l-50 -27 -312 -3 -313 -3 0 310 0 311 313 0 c306 0 313 -1 362 -24z m825
-118 c128 -243 164 -310 208 -389 23 -42 42 -79 42 -83 0 -3 -60 -6 -134 -6
l-135 0 -25 55 -25 55 -156 0 -155 0 -25 -55 -24 -55 -130 0 c-91 0 -131 3
-131 11 0 10 59 126 153 299 16 30 45 83 63 118 19 34 50 91 69 127 l36 65
147 0 147 0 75 -142z m900 47 l0 -95 -145 0 -145 0 0 -215 0 -215 -120 0 -120
0 -2 213 -3 212 -142 3 -143 3 0 94 0 95 410 0 410 0 0 -95z m580 -132 c68
-126 140 -260 161 -300 22 -39 39 -76 39 -82 0 -7 -43 -11 -130 -11 l-130 0
-27 55 -27 55 -153 0 -153 0 -27 -55 -27 -55 -133 0 c-85 0 -133 4 -133 10 0
6 6 21 14 33 15 23 37 64 208 384 l103 193 146 0 147 0 122 -227z"
          />
          <path
            d="M7667 9783 c-4 -3 -7 -55 -7 -115 l0 -109 156 3 156 3 24 28 c18 22
24 40 24 78 0 59 -20 93 -62 108 -33 12 -280 15 -291 4z m303 -53 c26 -26 26
-80 1 -111 -17 -21 -29 -24 -120 -27 -55 -2 -112 -1 -126 2 -24 6 -25 10 -25
81 l0 75 125 0 c112 0 127 -2 145 -20z"
          />
          <path
            d="M8655 9715 c-19 -41 -35 -79 -35 -85 0 -6 31 -10 81 -10 44 0 79 4
77 9 -18 48 -75 160 -81 161 -4 0 -23 -34 -42 -75z m55 -14 c0 -5 3 -16 6 -25
4 -12 0 -16 -15 -16 -22 0 -25 8 -15 34 7 17 24 22 24 7z"
          />
          <path
            d="M10090 9713 c-19 -41 -36 -80 -38 -84 -2 -5 33 -9 77 -9 45 0 81 4
81 9 0 15 -70 161 -78 161 -4 0 -23 -35 -42 -77z m70 -45 c0 -4 -12 -8 -26 -8
-14 0 -23 3 -21 8 2 4 7 16 11 27 7 20 8 20 22 1 8 -11 14 -23 14 -28z"
          />
          <path
            d="M3485 9009 c-71 -5 -150 -16 -175 -25 -25 -8 -67 -22 -95 -31 -46
-13 -142 -55 -205 -89 -14 -7 -41 -21 -60 -30 -68 -32 -79 -39 -136 -89 -113
-98 -324 -316 -324 -335 0 -5 -6 -15 -13 -22 -12 -12 -41 -71 -126 -250 -17
-36 -31 -79 -31 -94 0 -16 -12 -75 -26 -133 -24 -93 -26 -118 -21 -221 3 -71
13 -142 26 -187 12 -40 21 -89 21 -108 0 -20 13 -60 30 -92 17 -31 30 -61 30
-67 0 -7 13 -32 28 -57 16 -24 32 -57 36 -73 7 -29 63 -102 164 -215 71 -80
75 -84 148 -146 32 -27 74 -67 94 -87 19 -21 40 -38 46 -38 7 0 18 -7 25 -17
8 -9 33 -24 55 -35 23 -10 47 -25 54 -33 18 -22 86 -67 143 -94 26 -12 47 -27
47 -32 0 -5 19 -18 43 -30 23 -12 44 -25 47 -30 3 -5 21 -15 41 -23 19 -8 42
-23 52 -34 10 -11 33 -23 51 -26 18 -3 42 -15 54 -26 12 -12 41 -30 64 -41 24
-11 64 -32 89 -47 25 -15 66 -38 90 -51 24 -13 51 -29 59 -37 9 -7 35 -20 58
-28 23 -8 58 -26 79 -40 21 -15 45 -26 54 -26 9 0 24 -9 34 -21 11 -11 60 -41
110 -66 49 -25 92 -49 95 -54 3 -4 26 -16 50 -27 25 -11 54 -29 64 -41 11 -11
23 -21 29 -21 19 0 101 -56 179 -124 98 -84 200 -203 229 -265 12 -25 32 -68
45 -96 37 -80 46 -130 47 -284 1 -112 -3 -159 -16 -200 -23 -73 -67 -164 -83
-174 -8 -4 -21 -24 -29 -43 -16 -39 -19 -41 -150 -148 -96 -78 -213 -140 -283
-151 -24 -4 -69 -16 -100 -27 -115 -42 -514 -34 -612 11 -25 11 -61 21 -80 21
-19 0 -62 12 -95 26 -33 14 -82 32 -109 39 -27 8 -58 23 -70 33 -11 11 -35 23
-53 27 -17 3 -42 15 -55 26 -51 43 -119 89 -131 89 -18 0 -217 204 -217 223 0
9 -5 19 -10 22 -20 13 -98 140 -105 172 -3 18 -14 42 -24 55 -10 13 -26 55
-36 93 -9 39 -23 77 -31 85 -7 9 -21 58 -30 110 -9 52 -22 107 -30 122 -8 16
-19 60 -23 98 -5 39 -14 77 -20 84 -18 22 -66 38 -102 34 -66 -6 -64 22 -67
-818 -2 -415 0 -779 3 -808 9 -75 32 -96 97 -89 65 8 76 19 84 78 4 27 17 63
30 81 13 17 24 40 24 50 0 10 11 25 25 34 22 14 45 16 144 12 98 -4 127 -9
172 -30 31 -14 77 -28 105 -32 27 -4 66 -16 87 -27 22 -11 70 -26 108 -34 39
-8 84 -22 101 -31 18 -9 68 -24 112 -34 45 -10 99 -24 121 -33 40 -15 524 -45
542 -34 5 3 71 10 146 16 86 7 157 19 190 31 28 10 83 26 120 35 38 8 79 22
90 30 12 9 40 20 62 26 22 6 69 30 105 53 36 24 83 53 105 65 73 40 216 157
293 240 23 25 62 67 86 92 24 25 53 64 65 88 12 23 31 51 41 61 11 11 20 25
20 31 0 6 11 24 25 41 14 16 25 38 25 48 0 11 16 63 35 116 19 53 35 113 35
133 1 20 8 68 16 106 9 39 18 89 21 112 7 57 -43 368 -67 418 -10 22 -24 60
-30 85 -7 25 -18 52 -25 60 -8 8 -23 37 -35 65 -12 27 -25 52 -29 55 -4 3 -22
28 -41 55 -18 28 -43 61 -55 75 -13 14 -34 41 -47 61 -33 50 -157 160 -253
225 -44 29 -118 81 -165 113 -47 33 -98 68 -115 77 -16 9 -39 24 -50 34 -28
24 -204 128 -264 156 -18 8 -45 23 -60 33 -61 43 -285 170 -370 210 -23 12
-54 30 -68 41 -14 11 -34 20 -44 20 -9 0 -26 10 -36 23 -10 12 -34 30 -53 40
-19 9 -55 31 -80 47 -25 17 -65 40 -90 52 -25 13 -74 46 -109 75 -35 28 -76
55 -90 58 -14 4 -54 32 -87 63 -34 31 -83 73 -110 92 -83 60 -177 164 -208
230 -10 19 -25 50 -35 68 -27 48 -47 241 -32 310 6 29 11 70 11 90 0 20 9 53
19 72 11 19 29 53 39 76 11 23 31 52 44 66 108 115 151 155 202 189 33 22 64
39 70 39 5 0 20 9 32 21 14 13 50 26 92 34 39 8 85 22 104 32 27 15 62 18 196
21 142 3 171 1 231 -17 37 -11 98 -25 135 -30 37 -5 81 -18 99 -28 18 -11 55
-27 82 -35 28 -9 84 -36 125 -59 41 -23 85 -47 98 -54 20 -11 160 -133 202
-176 68 -70 108 -119 123 -150 9 -20 23 -42 31 -48 7 -6 20 -26 27 -44 7 -18
21 -44 31 -58 10 -15 23 -51 29 -80 6 -30 20 -70 30 -91 10 -20 27 -76 38
-125 11 -48 30 -121 41 -162 11 -41 20 -101 20 -132 0 -48 4 -60 22 -73 31
-22 107 -21 124 2 21 26 20 1630 0 1658 -11 14 -26 19 -60 19 -56 0 -73 -15
-90 -83 -8 -28 -23 -64 -34 -79 -10 -15 -23 -38 -26 -51 -9 -28 -32 -40 -103
-53 -73 -14 -161 2 -265 47 -42 19 -80 34 -84 34 -4 0 -26 9 -48 19 -89 42
-175 74 -258 97 -48 13 -101 30 -118 39 -58 30 -417 49 -635 34z"
          />
          <path
            d="M10017 9003 c-2 -1 -249 -4 -550 -5 l-547 -4 -34 -33 c-22 -21 -38
-50 -46 -80 -6 -25 -22 -63 -35 -83 -13 -20 -27 -49 -31 -65 -4 -15 -30 -75
-59 -133 -60 -119 -107 -225 -129 -288 -8 -23 -38 -89 -66 -145 -27 -57 -50
-107 -50 -111 0 -12 -81 -175 -90 -181 -4 -3 -14 -29 -20 -57 -7 -29 -24 -69
-36 -88 -13 -19 -24 -40 -24 -47 0 -7 -24 -64 -54 -126 -83 -173 -126 -268
-126 -280 0 -6 -10 -31 -21 -56 -12 -25 -39 -84 -61 -131 -22 -47 -52 -112
-67 -145 -16 -33 -40 -85 -54 -115 -14 -30 -29 -68 -33 -85 -4 -16 -20 -54
-35 -84 -16 -30 -29 -59 -29 -66 0 -7 -9 -24 -21 -39 -12 -15 -29 -53 -39 -86
-10 -33 -25 -67 -34 -75 -8 -9 -21 -35 -27 -58 -6 -23 -17 -49 -24 -57 -7 -8
-18 -33 -25 -55 -7 -22 -23 -60 -36 -85 -48 -95 -94 -197 -94 -211 0 -8 -9
-29 -21 -48 -11 -18 -27 -52 -36 -75 -9 -22 -22 -48 -29 -56 -7 -9 -18 -37
-24 -64 -6 -27 -22 -62 -35 -78 -14 -16 -25 -34 -25 -40 0 -6 -16 -47 -36 -92
-20 -44 -49 -110 -65 -146 -52 -116 -85 -175 -100 -175 -8 0 -18 20 -25 53 -7
29 -19 61 -27 70 -8 9 -20 34 -27 56 -7 21 -25 63 -41 93 -16 30 -29 63 -29
73 0 10 -6 23 -14 30 -8 6 -27 43 -41 81 -15 38 -31 71 -36 74 -4 3 -14 25
-20 48 -6 23 -19 53 -29 67 -10 14 -23 44 -29 68 -7 23 -23 59 -36 79 -14 20
-25 41 -25 47 0 6 -13 38 -30 71 -16 33 -30 67 -30 76 0 8 -13 35 -29 60 -16
24 -32 58 -36 76 -3 17 -13 37 -21 44 -8 6 -22 37 -31 68 -9 31 -22 65 -29 76
-7 11 -20 40 -28 63 -9 24 -24 55 -34 70 -10 15 -24 45 -31 67 -8 22 -21 51
-31 65 -9 14 -23 43 -30 65 -7 22 -19 47 -26 56 -8 9 -21 38 -29 66 -8 27 -23
58 -34 67 -10 10 -22 33 -25 51 -4 19 -21 65 -37 102 -55 120 -100 217 -119
253 -10 19 -21 52 -25 73 -4 21 -16 42 -26 47 -10 6 -25 32 -32 58 -8 26 -24
63 -36 82 -11 19 -21 41 -21 49 0 7 -25 63 -55 125 -30 61 -61 131 -70 156 -9
24 -22 51 -29 60 -8 8 -19 34 -25 56 -6 23 -20 52 -30 66 -11 13 -24 44 -31
68 -7 25 -20 56 -30 70 -9 14 -25 48 -36 75 -10 28 -26 64 -35 80 -9 17 -20
41 -24 55 -4 14 -16 36 -26 49 -11 13 -24 42 -30 65 -6 22 -16 48 -23 56 -7 8
-23 41 -36 73 -24 61 -50 75 -70 38 -13 -26 -14 -1488 0 -1560 9 -49 43 -136
94 -246 13 -27 29 -67 36 -87 6 -21 15 -40 20 -43 5 -3 16 -29 26 -58 9 -28
30 -76 45 -106 16 -30 29 -61 29 -70 0 -10 11 -32 25 -51 14 -19 25 -41 25
-50 0 -9 11 -39 25 -66 14 -27 30 -64 36 -82 6 -19 22 -52 35 -75 13 -23 24
-49 24 -57 0 -9 11 -31 24 -51 14 -20 32 -61 41 -92 9 -30 22 -60 30 -67 7 -6
16 -28 20 -50 4 -21 17 -53 31 -71 13 -18 24 -40 24 -50 0 -9 13 -40 29 -70
15 -30 34 -72 41 -94 7 -22 20 -51 30 -65 9 -14 20 -38 24 -54 4 -17 16 -43
28 -58 11 -16 26 -52 33 -80 8 -27 20 -57 28 -67 8 -9 20 -34 27 -56 7 -22 23
-58 36 -81 13 -22 24 -47 24 -55 0 -8 27 -68 60 -134 33 -66 60 -129 60 -141
0 -11 11 -35 24 -53 14 -19 28 -48 31 -65 4 -17 19 -56 35 -86 15 -30 31 -66
35 -80 4 -14 15 -36 25 -50 9 -14 23 -43 30 -65 7 -22 21 -50 30 -62 10 -13
21 -39 25 -60 3 -20 20 -61 36 -91 16 -30 29 -62 29 -71 0 -9 14 -36 30 -60
17 -24 30 -54 30 -66 0 -28 53 -111 81 -126 28 -15 125 -14 144 1 7 6 16 29
20 51 4 22 16 48 27 59 11 10 24 37 29 61 5 23 21 55 34 71 14 16 25 38 25 48
0 22 18 68 36 90 6 8 17 33 24 55 7 22 23 57 36 77 13 21 24 46 24 55 0 10 13
43 30 73 16 30 29 64 30 74 0 11 11 33 24 50 13 17 31 56 39 87 9 31 23 62 31
68 8 7 18 27 21 44 4 18 20 55 36 83 16 28 29 62 29 76 0 13 11 39 24 57 13
18 27 47 30 65 3 18 15 42 25 53 11 11 25 40 31 64 6 24 22 60 35 79 13 19 26
51 30 70 4 19 17 51 30 70 13 19 29 55 35 79 6 24 18 54 25 65 8 12 21 41 30
66 8 24 22 51 29 60 7 8 19 33 26 55 7 22 20 51 30 65 9 14 23 47 30 73 7 26
21 58 30 70 9 12 23 40 30 62 7 22 26 69 42 105 79 170 118 256 138 310 30 78
80 193 89 204 5 6 14 29 21 51 7 22 21 54 31 71 10 17 26 51 34 75 15 41 38
93 91 204 13 28 24 55 24 60 0 6 14 37 30 70 17 33 30 66 30 75 0 8 11 32 24
51 14 20 28 49 32 65 4 16 13 38 20 49 7 11 22 45 34 75 11 30 29 73 40 95 38
79 148 330 155 353 9 32 31 27 44 -9 8 -23 11 -520 11 -1718 0 -1819 3 -1687
-51 -1919 -26 -112 -78 -174 -194 -232 l-80 -40 -175 -4 c-165 -4 -176 -6
-192 -26 -18 -23 -25 -95 -10 -116 20 -30 52 -32 557 -23 281 4 728 5 995 0
542 -8 534 -9 546 55 8 44 -8 81 -39 93 -13 5 -98 12 -188 15 l-164 5 -77 41
c-85 44 -156 111 -175 165 -6 19 -22 82 -35 142 l-23 108 0 1905 c0 1830 1
1908 19 1985 21 91 64 188 98 225 13 14 50 43 83 66 67 46 75 48 319 67 175
14 186 19 186 84 0 30 -26 71 -47 74 -16 3 -234 5 -236 2z"
          />
          <path
            d="M2582 3529 c-142 -21 -229 -77 -250 -161 -36 -142 75 -206 383 -223
150 -8 185 -14 192 -32 3 -8 -4 -24 -15 -36 -17 -17 -37 -21 -106 -25 -106 -6
-147 2 -185 33 -27 24 -36 25 -151 25 l-123 0 6 -36 c12 -73 70 -130 162 -160
67 -21 316 -30 415 -15 86 13 195 63 223 102 27 37 38 97 26 141 -22 83 -125
122 -373 139 -171 12 -206 20 -206 44 0 26 46 46 118 52 93 8 156 -3 191 -33
27 -23 36 -24 150 -24 113 0 121 1 121 19 0 55 -47 119 -113 153 -85 43 -301
60 -465 37z"
          />
          <path
            d="M3487 3513 c-39 -65 -317 -593 -317 -602 0 -7 42 -11 130 -11 l130 0
25 55 24 55 156 0 155 0 27 -55 27 -55 134 0 c103 0 133 3 129 13 -2 6 -75
142 -161 302 -86 159 -156 293 -156 297 0 10 -297 11 -303 1z m193 -262 c22
-46 40 -84 40 -87 0 -2 -36 -4 -80 -4 -49 0 -80 4 -80 10 0 15 72 171 77 167
2 -3 21 -41 43 -86z"
          />
          <path
            d="M4129 3230 c-1 -159 -2 -298 -3 -307 -1 -17 23 -18 362 -21 l362 -2
0 100 0 100 -240 0 -240 0 0 210 0 210 -120 0 -120 0 -1 -290z"
          />
          <path
            d="M4930 3220 c0 -165 0 -303 0 -307 0 -5 53 -8 118 -8 l117 0 5 160 5
159 54 -74 c30 -41 84 -114 119 -162 l64 -88 57 0 c65 0 42 -23 212 210 l84
115 5 -160 5 -160 117 -3 c103 -2 117 -1 120 14 2 10 2 149 0 311 l-3 293
-152 -1 -152 0 -115 -150 c-63 -82 -117 -149 -121 -149 -6 0 -28 27 -172 213
l-68 87 -149 0 -150 0 0 -300z"
          />
          <path
            d="M6203 3217 c-90 -167 -163 -306 -163 -310 0 -4 60 -6 133 -5 l132 3
25 52 25 52 155 1 156 0 27 -55 27 -55 130 0 c71 0 130 4 130 9 0 9 -38 82
-225 429 l-98 182 -146 0 -146 0 -162 -303z m351 37 c20 -42 36 -80 36 -85 0
-5 -36 -9 -81 -9 -61 0 -80 3 -76 13 25 63 72 157 78 157 4 0 24 -34 43 -76z"
          />
          <path
            d="M7450 3209 l0 -310 308 4 c346 4 356 6 433 75 169 152 129 430 -75
523 -37 17 -71 19 -353 19 l-313 0 0 -311z m569 97 c49 -44 46 -145 -6 -186
-24 -19 -37 -20 -172 -18 l-146 3 -3 99 c-1 55 0 106 3 114 4 11 34 13 154 10
130 -3 152 -5 170 -22z"
          />
          <path
            d="M8489 3348 c-201 -374 -229 -429 -229 -438 0 -6 51 -10 131 -10 l130
0 25 55 25 55 154 0 154 0 28 -52 28 -53 133 -3 c72 -1 132 0 132 4 0 3 -23
49 -52 102 -162 300 -212 393 -240 450 l-32 62 -147 0 -147 0 -93 -172z m281
-94 c22 -48 40 -88 40 -90 0 -2 -36 -4 -80 -4 -44 0 -80 3 -80 8 0 9 72 172
76 172 2 0 21 -39 44 -86z"
          />
          <path
            d="M9040 3425 l0 -95 140 0 140 0 2 -212 3 -213 117 -3 118 -3 2 213 3
213 143 3 142 3 0 94 0 95 -405 0 -405 0 0 -95z"
          />
          <path
            d="M9861 3221 c-89 -164 -161 -304 -161 -310 0 -7 42 -11 131 -11 l130
0 25 55 25 55 154 0 154 0 28 -52 28 -53 133 -3 c72 -1 132 0 132 3 0 4 -36
72 -79 153 -150 278 -182 337 -214 400 l-31 62 -147 0 -147 0 -161 -299z m349
33 c22 -48 40 -88 40 -90 0 -2 -36 -4 -80 -4 -44 0 -80 3 -80 8 0 9 72 172 77
172 2 0 21 -39 43 -86z"
          />{' '}
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>; // Render logo without any link
  }

  // Render as external link
  return (
    <a
      href="https://www.aodata.ng"
      target="_blank"
      rel="noopener noreferrer"
      style={{ display: 'block', width: '100%', height: '100%' }}
    >
      {logo}
    </a>
  );
}
